// src/app/modules/ExamplesConfig/constants/menuConfig.js

// Archivo para importar la lista de opciones de la sección de configuración

const listMenu = [
  { title: 'Ubicación', list: ['General', 'Lista de espera', 'Reservaciones'] },
  { title: 'Clientes', list: ['Campos de entrada', 'Etiquetas de entrada'] },
  { title: 'Equipo', list: ['Roles', 'Recursos'] },
]

export default listMenu
