import React, { useEffect, useState } from 'react'
import { Card, Form, Input, Button, Select, message } from 'antd'
import BusinessService from 'src/services/BusinessService'

const { Option } = Select

const BusinessAddress = ({ businessId, fetchBusinessData, originalData }) => {
  const [form] = Form.useForm()
  const [countries, setCountries] = useState([]) // Estado para guardar los países

  // Obtener la lista de países al montar el componente
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await BusinessService.getCountries()
        if (response?.data) {
          setCountries(response.data) // Guardar los países en el estado
        } else {
          message.warning('No se pudieron cargar los países.')
        }
      } catch (error) {
        console.error('Error al obtener países:', error)
        message.error('Ocurrió un error al cargar la lista de países.')
      }
    }

    fetchCountries()
  }, [])

  // Sincronizar valores del formulario con originalData
  useEffect(() => {
    if (originalData) {
      form.setFieldsValue(originalData)
    }
  }, [originalData, form])

  // Guardar dirección de negocios
  const handleSaveAddress = async () => {
    try {
      const values = await form.validateFields([
        'address',
        'colony',
        'city',
        'postal_code',
        'country_id',
        'state_province',
      ])

      // Construir los datos a enviar
      const dataToSend = {
        address: values.address || '',
        colony: values.colony || '',
        city: values.city || '',
        postal_code: values.postal_code || '',
        country_id: values.country_id || '', // Enviar el ID del país
        state_province: values.state_province || '',
      }

      console.log('Datos a enviar (Dirección de Negocios):', JSON.stringify(dataToSend, null, 2))

      // Llamar a la API para actualizar la dirección
      const response = await BusinessService.setAddress(businessId, dataToSend)

      if (response?.status === 'OK') {
        message.success('Dirección actualizada correctamente')
        fetchBusinessData(businessId) // Refrescar datos
      } else {
        message.warning(response?.message || 'Error al actualizar la dirección')
      }
    } catch (error) {
      console.error('Error al intentar actualizar la dirección:', error)
      message.error('Por favor, completa los campos correctamente.')
    }
  }

  return (
    <Card className="bg-white rounded shadow-none mt-4">
      <h3 className="font-semibold">Dirección de Negocios</h3>
      <p style={{ color: '#6c757d' }}>Ingresa la dirección de tu negocio.</p>

      <Form form={form} layout="vertical">
        <Form.Item name="address" label="Dirección">
          <Input placeholder="Dirección" />
        </Form.Item>
        <Form.Item name="colony" label="Colonia">
          <Input placeholder="Colonia" />
        </Form.Item>
        <Form.Item name="city" label="Ciudad">
          <Input placeholder="Ciudad" />
        </Form.Item>
        <Form.Item name="postal_code" label="Código postal">
          <Input placeholder="Código postal" />
        </Form.Item>
        <Form.Item
          name="country_id"
          label="País"
          rules={[{ required: true, message: 'Por favor selecciona un país' }]}
        >
          <Select placeholder="Selecciona un país">
            {countries.map((country) => (
              <Option key={country.id} value={country.id}>
                {country.es_name} {/* Mostrar el nombre del país en español */}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="state_province" label="Provincia del estado">
          <Input placeholder="Provincia del estado" />
        </Form.Item>

        <Button type="primary" onClick={handleSaveAddress} style={{ marginTop: '16px' }}>
          Guardar Dirección
        </Button>
      </Form>
    </Card>
  )
}

export default BusinessAddress
