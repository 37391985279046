import {SharedService} from 'src/services'
import {constants} from 'src/utils'
export const BASE_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:3200';


const UploadService = {
  uploadImage: async (file, folder) => {

    return new Promise( async (resolve, reject) => {

      try {
        
        let response = await SharedService.uploadImageFile(file, folder)

        if(response.status === constants.response.success){

          resolve({
            success: true,
            filename: response.data.filename,
          })

        }else{

          resolve({
            success: false,
            message: response.message,
          })
          
        }

      } catch (error) {
        resolve({
          success: false,
          message: 'Error desconocido',
        })
      }

    })

  }
}

export default UploadService