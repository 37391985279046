import React, { useMemo, useState, useEffect } from 'react'
import objectPath from 'object-path'
import { useHtmlClassService } from '../../_core/MetronicLayout'
import { AnimateLoading } from '../../../_partials/controls'
import { Brand } from '../brand/Brand'
import { QuickSearch } from '../extras/offcanvas/QuickSearch'
import SessionService from 'src/services/SessionService'
import { useHistory } from 'react-router-dom'
import BookingService from 'src/services/BookingService'
import { Select } from 'antd'
import { TopbarUser } from '../header/TopbarUser' // Importar TopbarUser

export function Header() {
  const uiService = useHtmlClassService()
  const history = useHistory()
  const [businesses, setBusinesses] = useState([])
  const [selectedBusiness, setSelectedBusiness] = useState(null)

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses('header', true),
      headerAttributes: uiService.getAttributes('header'),
      headerContainerClasses: uiService.getClasses('header_container', true),
      menuHeaderDisplay: objectPath.get(uiService.config, 'header.menu.self.display'),
    }
  }, [uiService])

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const response = await SessionService.getSession()
        const userBusinesses = response.data.business
        setBusinesses(userBusinesses)

        // Recupera el ID del negocio desde localStorage
        const savedBusinessId = localStorage.getItem('provider_tappit_user_business_id')
        setSelectedBusiness(savedBusinessId || userBusinesses[0]?.business_id)
      } catch (error) {
        console.error('Error fetching session data:', error)
      }
    }

    fetchSessionData()
  }, [])

  const handleBusinessChange = async (value) => {
    setSelectedBusiness(value);
  
    // Obtén la URL actual
    const currentPath = window.location.pathname;
  
    // Reemplaza dinámicamente el ID del negocio en la URL actual
    const updatedPath = currentPath.replace(/\/business\/[^/]+/, `/business/${value}`);
  
    console.log(`Cambiando a business ID: ${value} en la URL: ${updatedPath}`);
  
    try {
      // Actualiza el ID del negocio en localStorage
      localStorage.setItem('provider_tappit_user_business_id', value);
  
      // Fuerza un reload de la vista actualizada
      window.location.href = updatedPath;
    } catch (error) {
      console.error('Error al cargar los datos del negocio:', error);
    }
  };
  
  

  return (
    <>
      {/*begin::Header*/}

      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px 15px',
          borderBottom: '1px solid #ddd',
          backgroundColor: '#fff',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1100,
        }}
      >
        {/* Logo Section */}
        {/* <TopbarUser /> */}
        <div
          style={{
            marginLeft: window.innerWidth < 768 ? '40px' : '40px', // Aumenta el margen en móviles
            flex: window.innerWidth < 768 ? 'none' : 1, // Permitir que el logo tome el espacio necesario solo en desktop
            display: 'flex',
            justifyContent: 'flex-start', // Asegura que esté alineado a la izquierda en móvil
          }}
        >
          <a href="/visitas">
            <img
              src="/media/logos/logo-panel.png"
              alt="Logo"
              style={{
                maxWidth: window.innerWidth < 768 ? '80px' : '120px', // Ajustar tamaño condicional para móvil y desktop
                height: 'auto',
                cursor: 'pointer', // Muestra que la imagen es clickeable
              }}
            />
          </a>
        </div>

        {/* Select and QuickSearch */}
        <div
          className="d-flex align-items-center"
          style={{
            flex: 1,
            justifyContent: 'flex-end',
          }}
        >
          <Select
            style={{
              fontSize: '1.25rem',
              minWidth: '200px',
              maxWidth: window.innerWidth < 768 ? '150px' : '200px', // Ajustar tamaño para móviles
              backgroundColor: 'transparent',
              border: '1px solid #d9d9d9',
              marginRight: window.innerWidth < 768 ? '10px' : '20px',
            }}
            value={selectedBusiness}
            onChange={handleBusinessChange}
            bordered={false}
            dropdownStyle={{
              backgroundColor: 'white',
              border: '1px solid #d9d9d9',
              zIndex: 1500, // Asegura que las opciones estén por encima de otros elementos
            }}
            placeholder="Seleccionar negocio"
          >
            {businesses.map((business) => (
              <Select.Option key={business.business_id} value={business.business_id}>
                {business.name}
              </Select.Option>
            ))}
          </Select>

          {/* Ocultar búsqueda en móviles */}
          {window.innerWidth >= 768 && <QuickSearch />}
          {/* {window.innerWidth >= 768 && <TopbarUser />} */}
        </div>

        
      </div>
      {/*end::Header*/}
    </>
  )
}
