/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'
import routesMap from '../../../../../routesMap'
import PermissionWrapper from 'src/app/components/utils/permission-wrapper'
import { useSelector } from 'react-redux'
import { SessionService } from 'src/services'
import { Tooltip } from 'antd' // Importar Tooltip de Ant Design
import {
  SolutionOutlined,
  QrcodeOutlined,
  TeamOutlined,
  UserOutlined,
  UserSwitchOutlined,
  ScanOutlined,
  CalendarOutlined,
  UsergroupAddOutlined,
  UnorderedListOutlined,
  SignalFilled,
  CreditCardOutlined,
} from '@ant-design/icons' // ant

//
export function AsideMenuList({ layoutProps }) {
  const location = useLocation()
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open menu-item-not-hightlighted`
      : ''
  }
  //
  const updateMenuTours = useSelector((state) => state.refreshTours)

  const [toursList, setTourList] = useState([])

  const getListTours = async () => {
    try {
      let response = await SessionService.getSession()
      if (response.status === 'OK') {
        setTourList(response?.data?.tours || [])
      }
    } catch (error) {}
  }

  useEffect(() => {
    getListTours()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateMenuTours])

  return (
    <>
      {/* Navbar */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}

        {/* Visitas */}
        <li
          className={`menu-item ${getMenuItemActive(routesMap.visitasBooking.path, false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={routesMap.visitasBooking.path}>
            <Tooltip title={routesMap.visitasBooking.name || 'Visitas'} placement="right">
              <span className="menu-icon">
                <UnorderedListOutlined />
                {/* Este es el nuevo icono */}
              </span>
            </Tooltip>
          </NavLink>
        </li>

        {/* Checking QR */}
        <li
          className={`menu-item ${getMenuItemActive(routesMap.checkins.path, false)}`}
          aria-haspopup="true"
        >
          <PermissionWrapper module="mdu_permission_user">
            <NavLink
              className={`menu-link ${getMenuItemActive(routesMap.checkins.path, false)}`}
              to={routesMap.checkins.path}
            >
              <Tooltip title={routesMap.checkins.name || 'Checking QR'} placement="right">
                <span className={`menu-icon ${getMenuItemActive(routesMap.checkins.path)}`}>
                  <ScanOutlined />
                  
                </span>
              </Tooltip>
            </NavLink>
          </PermissionWrapper>
        </li>

        {/* Pago stripe */}
        {/* <li
          className={`menu-item ${getMenuItemActive(routesMap.subscription.path, false)}`}
          aria-haspopup="true"
        >
          <PermissionWrapper module="mdu_permission_user">
            <NavLink
              className={`menu-link ${getMenuItemActive(routesMap.subscription.path, false)}`}
              to={routesMap.subscription.path}
            >
              <Tooltip title={routesMap.subscription.name || 'Membresia'} placement="right">
                <span className={`menu-icon ${getMenuItemActive(routesMap.subscription.path)}`}>
                  <CreditCardOutlined />
                  
                </span>
              </Tooltip>
            </NavLink>
          </PermissionWrapper>
        </li> */}

        {/* Calendario */}
        {/* <li
          className={`menu-item ${getMenuItemActive(routesMap.calendar.path, false)}`}
          aria-haspopup="true"
        >
          <PermissionWrapper module="mdu_permission_user">
            <NavLink
              className={`menu-link ${getMenuItemActive(routesMap.calendar.path)}`}
              to={routesMap.calendar.path}
            >
              <Tooltip title={routesMap.calendar.name || 'Calendario'} placement="right">
                <span className={`menu-icon ${getMenuItemActive(routesMap.calendar.path)}`}>
                  <CalendarOutlined />
                </span>
              </Tooltip>
            </NavLink>
          </PermissionWrapper>
        </li> */}

        {/* Sección para RP */}
        <li
          className={`menu-item ${getMenuItemActive(routesMap.rp.path, false)}`}
          aria-haspopup="true"
        >
          <PermissionWrapper module="mdu_permission_user">
            <NavLink
              className={`menu-link ${getMenuItemActive(routesMap.rp.path)}`}
              to={routesMap.rp.path}
            >
              <Tooltip title={routesMap.rp.name || 'RP'} placement="right">
                <span className={`menu-icon ${getMenuItemActive(routesMap.rp.path)}`}>
                  <UsergroupAddOutlined />
                  {/* Este es el icono que se mostrará */}
                </span>
              </Tooltip>
            </NavLink>
          </PermissionWrapper>
        </li>

        {/* Clientes */}
        <li
          className={`menu-item ${getMenuItemActive(routesMap.customer.path, false)}`}
          aria-haspopup="true"
        >
          <PermissionWrapper module="mdu_permission_user">
            <NavLink
              className={`menu-link ${getMenuItemActive(routesMap.customer.path)}`}
              to={routesMap.customer.path}
            >
              <Tooltip title={routesMap.customer.name || 'Clientes'} placement="right">
                <span className={`menu-icon ${getMenuItemActive(routesMap.customer.path)}`}>
                  <TeamOutlined />
                  {/* Este es el icono que se mostrará */}
                </span>
              </Tooltip>
            </NavLink>
          </PermissionWrapper>
        </li>

        {/* Usuarios */}
        <PermissionWrapper modules={['mdu_user', 'mdu_permission_user']}>
          <li
            className={`menu-item ${getMenuItemActive(routesMap.user.path, false)}`}
            aria-haspopup="true"
          >
            <PermissionWrapper module="mdu_user">
              <NavLink
                className={`menu-link ${getMenuItemActive(routesMap.user.path)}`}
                to={routesMap.user.path}
              >
                <Tooltip title={routesMap.user.name || 'Usuarios'} placement="right">
                  <span className={`menu-icon ${getMenuItemActive(routesMap.user.path)}`}>
                    <span className="fa fa-users"></span>
                  </span>
                </Tooltip>
              </NavLink>
            </PermissionWrapper>
          </li>
        </PermissionWrapper>

        {/* Permisos */}
        <li
          className={`menu-item ${getMenuItemActive(routesMap.permission.path, false)}`}
          aria-haspopup="true"
        >
          <PermissionWrapper module="mdu_permission_user">
            <NavLink
              className={`menu-link ${getMenuItemActive(routesMap.permission.path)}`}
              to={routesMap.permission.path}
            >
              <Tooltip title={routesMap.permission.name || 'Permisos'} placement="right">
                <span className={`menu-icon ${getMenuItemActive(routesMap.permission.path)}`}>
                  <SolutionOutlined />
                  {/* Este es el icono que se mostrará */}
                </span>
              </Tooltip>
            </NavLink>
          </PermissionWrapper>
        </li>

        {/* Negocios */}
        {/* <PermissionWrapper modules={['mdu_business_crud', 'mdu_business_config']}>
          <li
            className={`menu-item ${getMenuItemActive(routesMap.business.path, false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={routesMap.business.path}>
              <span className="svg-icon menu-icon">
                <span className="fa fa-comments"></span>
              </span>
              <span className="menu-text">{routesMap.business.name}</span>
            </NavLink>
          </li>
        </PermissionWrapper> */}

        {/*Apartado de calendario*/}

        {/*} <PermissionWrapper modules={['mdu_business_crud', 'mdu_business_config']}>
          <li
            className={`menu-item ${getMenuItemActive(routesMap.calendar.path, false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={routesMap.calendar.path}>
              <span className="svg-icon menu-icon">
                <span className="fa fa-calendar"></span>
              </span>
              <span className="menu-text">{routesMap.calendar.name}</span>
            </NavLink>
          </li>
        </PermissionWrapper>*/}

        {/* SECCION CUENTAS */}

        {/* Negocios */}
        <PermissionWrapper modules={['mdu_business_crud', 'mdu_business_config']}>
          <li
            className={`menu-item ${getMenuItemActive(routesMap.business.path, false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={routesMap.business.path}>
              <Tooltip title={routesMap.business.name || 'Negocios'} placement="right">
                <span className="svg-icon menu-icon">
                  <span className="fa fa-building"></span>
                </span>
              </Tooltip>
              <span className="menu-text">{routesMap.business.name}</span>
            </NavLink>
          </li>
        </PermissionWrapper>

        {/* Configuración */}
        <PermissionWrapper modules={['mdu_business_config']}>
          <li
            className={`menu-item ${getMenuItemActive(routesMap.settings.path, false)}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to={routesMap.settings.path}>
              <Tooltip title={routesMap.settings.name || 'Configuración'} placement="right">
                <span className="svg-icon menu-icon">
                  <span className="fas fa-cog"></span>
                </span>
              </Tooltip>
              <span className="menu-text">{routesMap.settings.name}</span>
            </NavLink>
          </li>
        </PermissionWrapper>
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}
