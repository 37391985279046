import React, { useState, useEffect } from 'react'
import { Card, Button, Modal, Form, Select, TimePicker, message } from 'antd'
import moment from 'moment'
import BusinessService from 'src/services/BusinessService'

const { Option } = Select

const daysOfWeek = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo']

const Schedules = ({ businessId, schedules, setSchedules, fetchBusinessData }) => {
  const [modalForm] = Form.useForm()
  const [scheduleModalVisible, setScheduleModalVisible] = useState(false)
  const [additionalPeriods, setAdditionalPeriods] = useState(
    daysOfWeek.reduce((acc, day) => {
      acc[day.toLowerCase()] = []
      return acc
    }, {}),
  )

  // Formatea los horarios para mostrarlos en la vista
  const formatSchedules = (schedules) => {
    const dayMapping = {
      mon: 'Lunes',
      tue: 'Martes',
      wed: 'Miércoles',
      thu: 'Jueves',
      fri: 'Viernes',
      sat: 'Sábado',
      sun: 'Domingo',
    }

    return Object.entries(dayMapping).map(([key, dayName]) => {
      const schedule = schedules[key]
      if (schedule?.isOpen) {
        const periods = schedule.periods.map(({ from, to }) => `${from} - ${to}`).join(', ')
        return `${dayName}: ${periods}`
      } else {
        return `${dayName}: Cerrado`
      }
    })
  }

  // Abre el modal y configura los valores iniciales
  const openScheduleModal = () => {
    const initialFormValues = {}
    Object.entries(schedules).forEach(([dayKey, schedule]) => {
      const dayMapping = {
        mon: 'Lunes',
        tue: 'Martes',
        wed: 'Miércoles',
        thu: 'Jueves',
        fri: 'Viernes',
        sat: 'Sábado',
        sun: 'Domingo',
      }

      const dayName = dayMapping[dayKey]
      if (!dayName) return

      if (schedule?.isOpen) {
        initialFormValues[`${dayName.toLowerCase()}_isOpen`] = 'Open'
        if (schedule.periods?.length > 0) {
          schedule.periods.forEach((period, index) => {
            if (index === 0) {
              initialFormValues[`${dayName.toLowerCase()}_from`] = moment(period.from, 'h:mm A')
              initialFormValues[`${dayName.toLowerCase()}_to`] = moment(period.to, 'h:mm A')
            } else {
              addPeriod(dayName)
              modalForm.setFieldsValue({
                [`${dayName.toLowerCase()}_additional_from_${index - 1}`]: moment(
                  period.from,
                  'h:mm A',
                ),
                [`${dayName.toLowerCase()}_additional_to_${index - 1}`]: moment(
                  period.to,
                  'h:mm A',
                ),
              })
            }
          })
        }
      } else {
        initialFormValues[`${dayName.toLowerCase()}_isOpen`] = 'Closed'
      }
    })

    modalForm.setFieldsValue(initialFormValues)
    setScheduleModalVisible(true)
  }

  // Guarda los horarios actualizados
  const handleSaveSchedules = async () => {
    try {
      const values = await modalForm.validateFields()
      const schedulesPayload = transformFormToSchedules(values)

      console.log(
        'Datos que se enviarán a la API:',
        JSON.stringify({ schedules: schedulesPayload }, null, 2),
      )

      await BusinessService.setSchedules(businessId, { schedules: schedulesPayload })

      message.success('Horarios actualizados correctamente')
      setScheduleModalVisible(false)
      fetchBusinessData(businessId)
    } catch (error) {
      console.error('Error al guardar los horarios:', error)
      message.error('Error al guardar los horarios')
    }
  }

  // Transforma los datos del formulario al formato requerido por la API
  const transformFormToSchedules = (values) => {
    const dayMapping = {
      Lunes: 'mon',
      Martes: 'tue',
      Miércoles: 'wed',
      Jueves: 'thu',
      Viernes: 'fri',
      Sábado: 'sat',
      Domingo: 'sun',
    }

    const schedules = {}

    Object.keys(dayMapping).forEach((day) => {
      const dayKey = dayMapping[day]
      const isOpen = values[`${day.toLowerCase()}_isOpen`] === 'Open'

      const primaryFrom = values[`${day.toLowerCase()}_from`]?.format('h:mm A') || ''
      const primaryTo = values[`${day.toLowerCase()}_to`]?.format('h:mm A') || ''

      const additionalPeriodsForDay =
        additionalPeriods[day.toLowerCase()]
          ?.map((_, index) => {
            const additionalFrom =
              values[`${day.toLowerCase()}_additional_from_${index}`]?.format('h:mm A') || ''
            const additionalTo =
              values[`${day.toLowerCase()}_additional_to_${index}`]?.format('h:mm A') || ''
            return additionalFrom && additionalTo
              ? { from: additionalFrom, to: additionalTo }
              : null
          })
          .filter(Boolean) || []

      schedules[dayKey] = {
        isOpen,
        periods: isOpen
          ? [
              ...(primaryFrom && primaryTo ? [{ from: primaryFrom, to: primaryTo }] : []),
              ...additionalPeriodsForDay,
            ]
          : [],
      }
    })

    return schedules
  }

  // Añade un nuevo periodo para un día
  const addPeriod = (day) => {
    setAdditionalPeriods((prev) => {
      const updated = { ...prev }
      updated[day.toLowerCase()] = [...(updated[day.toLowerCase()] || []), { from: null, to: null }]
      return updated
    })
  }

  // Eliminar un periodo adicional específico
  const removePeriod = (day, index) => {
    setAdditionalPeriods((prev) => {
      const updated = { ...prev }
      updated[day.toLowerCase()] = updated[day.toLowerCase()].filter((_, idx) => idx !== index)
      return updated
    })
  }

  return (
    <Card className="bg-white rounded shadow-none mt-4">
      <h3 className="fw-semibold">Horas</h3>
      <p style={{ color: '#6c757d' }}>Establece tu horario de apertura y zona horaria.</p>

      <Form.Item label="Zona horaria" name="timezone">
        <Select placeholder="Selecciona la zona horaria" style={{ width: '100%' }}>
          <Select.Option value="gmt-5">GMT-5</Select.Option>
          <Select.Option value="gmt-6">GMT-6</Select.Option>
          <Select.Option value="gmt-7">GMT-7</Select.Option>
        </Select>
      </Form.Item>

      <h4 className="fw-semibold mt-4">Horario de apertura</h4>

      <div
        style={{
          marginBottom: '16px',
          display: 'grid',
          gridTemplateColumns: '0.3fr 1.5fr',
          gap: '4px',
        }}
      >
        {formatSchedules(schedules).map((schedule, index) => {
          const [day, hours] = schedule.split(': ')
          return (
            <React.Fragment key={index}>
              <div style={{ fontWeight: 'bold', margin: 0 }}>{day}</div>
              <div style={{ margin: 0 }}>{hours}</div>
            </React.Fragment>
          )
        })}
      </div>

      <Button type="default" onClick={openScheduleModal} style={{ marginTop: '16px' }}>
        Establecer horario de apertura
      </Button>

      <Modal
        title="Editar horarios de apertura"
        visible={scheduleModalVisible}
        onCancel={() => setScheduleModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setScheduleModalVisible(false)}>
            Cancelar
          </Button>,
          <Button key="save" type="primary" onClick={handleSaveSchedules}>
            Guardar
          </Button>,
        ]}
      >
        <Form form={modalForm} layout="vertical">
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 5.5fr', gap: '8px' }}>
            {daysOfWeek.map((day) => (
              <React.Fragment key={day}>
                <div style={{ fontWeight: 'bold', marginTop: '8px' }}>{day}</div>
                <div>
                  <Form.Item
                    name={`${day.toLowerCase()}_isOpen`}
                    rules={[{ required: true, message: 'Selecciona Open o Closed' }]}
                    style={{ marginBottom: 0 }}
                  >
                    <Select style={{ width: 100 }} defaultValue="Open">
                      <Select.Option value="Open">Open</Select.Option>
                      <Select.Option value="Closed">Closed</Select.Option>
                    </Select>
                  </Form.Item>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                      marginTop: '8px',
                    }}
                  >
                    {/* Primer periodo (siempre presente) */}
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <Form.Item name={`${day.toLowerCase()}_from`} style={{ flex: 1 }}>
                        <TimePicker use12Hours format="h:mm A" placeholder="Desde" />
                      </Form.Item>
                      <Form.Item name={`${day.toLowerCase()}_to`} style={{ flex: 1 }}>
                        <TimePicker use12Hours format="h:mm A" placeholder="Hasta" />
                      </Form.Item>
                      <Button type="link" onClick={() => addPeriod(day)}>
                        +
                      </Button>
                    </div>

                    {/* Periodos adicionales con botón (-) */}
                    {additionalPeriods[day.toLowerCase()]?.map((_, index) => (
                      <div
                        key={index}
                        style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
                      >
                        <Form.Item
                          name={`${day.toLowerCase()}_additional_from_${index}`}
                          style={{ flex: 1 }}
                        >
                          <TimePicker use12Hours format="h:mm A" placeholder="Desde" />
                        </Form.Item>
                        <Form.Item
                          name={`${day.toLowerCase()}_additional_to_${index}`}
                          style={{ flex: 1 }}
                        >
                          <TimePicker use12Hours format="h:mm A" placeholder="Hasta" />
                        </Form.Item>
                        <Button
                          type="link"
                          danger
                          onClick={() => removePeriod(day, index)} // Llama a la función para eliminar este periodo
                        >
                          -
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        </Form>
      </Modal>
    </Card>
  )
}

export default Schedules
