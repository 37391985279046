import api from 'src/services/api'

const BusinessService = {
  getAll: (data) => api.get('/v1/provider/business', data),
  create: (data) => api.post('/v1/provider/business', data),
  getOne: (id, data) => api.get('/v1/provider/business/' + id, data), //se modifico este endpoint se le agrego data
  update: (id, data) => api.put('/v1/provider/business/' + id, data),
  delete: (id) => api.delete('/v1/provider/business/' + id),
  updateEnabled: (id) => api.put('/v1/provider/business/' + id + '/enabled'),

  getUserList: () => api.get('/v1/provider/business/user-list'),
  getAllUsers: (businessId, data) => api.get('/v1/provider/business/' + businessId + '/user', data),
  createUser: (businessId, data) => api.post('/v1/provider/business/' + businessId + '/user', data),
  deleteUser: (businessId, userId) =>
    api.delete('/v1/provider/business/' + businessId + '/user/' + userId),

  getAllTours: (businessId, data) => api.get('/v1/provider/business/' + businessId + '/tour', data),
  getOneTour: (businessId, tourId) =>
    api.get('/v1/provider/business/' + businessId + '/tour/' + tourId),
  createTour: (businessId, data) => api.post('/v1/provider/business/' + businessId + '/tour', data),
  updateTour: (businessId, tourId, data) =>
    api.put('/v1/provider/business/' + businessId + '/tour/' + tourId, data),
  deleteTour: (businessId, tourId) =>
    api.delete('/v1/provider/business/' + businessId + '/tour/' + tourId),
  updateEnableTour: (businessId, tourId) =>
    api.put('/v1/provider/business/' + businessId + '/tour/' + tourId + '/enable'),
  validateTourSlug: (businessId, data) =>
    api.post('/v1/provider/business/' + businessId + '/tour/validate-slug', data),

  getTourBookingNotification: (businessId, tourId) =>
    api.get(`/v1/provider/business/${businessId}/tour/${tourId}/booking-notification`),
  createTourBookingNotification: (businessId, tourId, data) =>
    api.post(`/v1/provider/business/${businessId}/tour/${tourId}/booking-notification`, data),
  updateTourBookingNotification: (businessId, tourId, configNotifId, data) =>
    api.put(
      `/v1/provider/business/${businessId}/tour/${tourId}/booking-notification/${configNotifId}`,
      data,
    ),
  deleteTourBookingNotification: (businessId, tourId, configNotifId) =>
    api.delete(
      `/v1/provider/business/${businessId}/tour/${tourId}/booking-notification/${configNotifId}`,
    ),

  getSharedVacantsGroup: (businessId) =>
    api.get(`/v1/provider/business/${businessId}/shared-vacants-group`),
  createSharedVacantsGroup: (businessId, data) =>
    api.post(`/v1/provider/business/${businessId}/shared-vacants-group`, data),
  updateSharedVacantsGroup: (businessId, svgId, data) =>
    api.put(`/v1/provider/business/${businessId}/shared-vacants-group/${svgId}`, data),
  deleteSharedVacantsGroup: (businessId, svgId) =>
    api.delete(`/v1/provider/business/${businessId}/shared-vacants-group/${svgId}`),

  getTourTax: (businessId) => api.get(`/v1/provider/business/${businessId}/tour-tax`),
  getOneTourTax: (businessId, ttId) =>
    api.get(`/v1/provider/business/${businessId}/tour-tax/${ttId}`),
  createTourTax: (businessId, data) =>
    api.post(`/v1/provider/business/${businessId}/tour-tax`, data),
  updateTourTax: (businessId, ttId, data) =>
    api.put(`/v1/provider/business/${businessId}/tour-tax/${ttId}`, data),
  deleteTourTax: (businessId, ttId) =>
    api.delete(`/v1/provider/business/${businessId}/tour-tax/${ttId}`),

  // Creación de recursos
  createResource: (businessId, data) =>
    api.post(`/v1/provider/business/${businessId}/resources`, data),

  // Actualiza datos del negocio
  updateBusiness: (id, data) => api.put(`/v1/provider/business/${id}/updatebusiness`, data),

  // Función para obtener la información de un negocio por su ID
  getBusinessById: (id) => api.get(`/v1/provider/business/${id}`),

  //endpoint a utilizar para crear reserva
  updateTypeBooking: (BId, data) => api.put(`/v1/client/business/${BId}/use_booking`, data),

  updateSchedules: (BId, data) => api.put(`/v1/client/business/${BId}/set_schedule`, data),

  getAvaibleHours: (BId, date) => api.get(`/v1/client/business/${BId}/hours?date=${date}`),

  getAvailableResources: (businessId) =>
    api.get(`/v1/client/business/${businessId}/resources/available`),

  // Nueva función para obtener datos del cliente por teléfono
  getCustomerByPhone: (businessId, phone) =>
    api.get(`/v1/client/business/${businessId}/customer/${phone}`),

  //SECCIÓN DE CONFIGURACIÓN
  //Configuración General
  setGeneralData: (businessId, data) =>
    api.put(`/v1/provider/business/${businessId}/set-general-data`, data),

  // Horas
  // setSchedules: (businessId, data) =>
  //   api.put(`/v1/provider/business/${businessId}/set-schedules`, data),

  setSchedules: (businessId, data) =>
    api.put(`/v1/provider/business/${businessId}/set-squedules`, data),

  // Información Comercial
  setComercialInformation: (businessId, data) =>
    api.put(`/v1/provider/business/${businessId}/set-comercial-information`, data),

  // Dirección de negocios
  setAddress: (businessId, data) =>
    api.put(`/v1/provider/business/${businessId}/set-address`, data),

  //Obtener países
  getCountries: () => {
    return api.get(`/v1/provider/business/countries`)
  },
}

export default BusinessService
