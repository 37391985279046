import { Card, Row, Col, Button, Table, Tooltip } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import SubMenu from './SubMenu';

export default function SettingsEquipo({ settingFormInfo }) {
  const [listMenu, setListMenu] = useState([
    { title: 'Ubicación', list: ['general', 'lista de espera', 'reservaciones'] },
    { title: 'Clientes', list: ['campos de entrada', 'etiquetas de entrada'] },
    { title: 'Equipo', list: ['Usuarios', 'Roles', 'Recursos'] } // Sección "Equipo"
  ]);

  const [data, setData] = useState([
    { key: 1, name: 'Juan', role: 'Owner', email: 'prueba@gmail.com' },
    { key: 2, name: 'Sergio Mata', role: 'Admin', email: 'naturotreadds@gmail.com' },
    { key: 3, name: 'Héctor', role: 'Editor', email: 'hector.martinez.pinedo@gmail.com' },
  ]);

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Rol',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Correo Electrónico',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Acciones',
      key: 'actions',
      render: (text, record) => (
        <span>
          <Tooltip title="Editar">
            <Button type="primary" shape="circle" icon={<EditOutlined />} />
          </Tooltip>
          <Tooltip title="Eliminar">
            <Button type="danger" shape="circle" icon={<DeleteOutlined />} className="ml-2" />
          </Tooltip>
        </span>
      ),
    },
  ];

  return (
    <>
      <Row>
        <Col xs={5}>
          <SubMenu settingsList={listMenu} />
        </Col>
        <Col xs={19} className='pl-2'>

          {/* Sección de "Equipo" */}
          <Card className='bg-white rounded shadow-none'>
            <h2 className='fw-bold'>{settingFormInfo || "Equipo"}</h2>
          </Card>

          {/* Sección de Usuarios */}
          <Card className='bg-white rounded shadow-none'>
            <Row justify="space-between" align="middle">
              <Col>
                <h2 className="fw-bold">Usuarios</h2>
              </Col>
              <Col>
                <Button type="primary" icon={<PlusOutlined />}>
                  Invitar Usuario
                </Button>
              </Col>
            </Row>

            <Table columns={columns} dataSource={data} className="mt-4" />
          </Card>

          {/* Otras secciones como "Roles" y "Recursos" podrían añadirse aquí */}
          
        </Col>
      </Row>
    </>
  );
}
